<script setup>
import ProjectTable from '@/components/ProjectTable';
import Pagination from '@/components/Pagination';
import AppealFilter from '@/components/appeals/AppealFilter';
import AppealSearch from '@/components/appeals/AppealSearch';
import { ref, onMounted, computed, reactive, defineProps, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { getAppeals, getArchiveAppeals } from '@/api/appeals';


const content = ref(null);

const props = defineProps({
  user: Object,
  initThemes: Object,
  initOperators: Array,
  archive: Boolean
});

const router = useRouter();

const appeals = reactive({items: []});
const totalAppealsCount = ref(0);

const searchParams = reactive({
  column: 'date',
  descending: true,
  selectedPage: 1,
  pageSize: 30,
  search: ''
});

const pagination = reactive({
  pMin: 1,
  pDelta: 3,
  pMax: 0
});

const filter = reactive({
  themes: [],
  themesCollapsed: false,
  operators: [],
  operatorsCollapsed: false,
  statuses: [],
  statusesCollapsed: false,
  minDate: [],
  maxDate: [],
  checkboxSelect: {
    status: [],
    operator: [],
    theme: []
  }
});

const filterParams = reactive({
  selectedOperators: [],
  selectedThemes: [],
  selectedStatuses: [],
  selectedDateRange: {
    startDate: '',
    endDate: ''
  }
});

const flags = reactive({
  appealsLoading: true,
  fromLoad: false,
  error: false,
  emptyOrgAppeals: false,
  tableOverflownX: false,
  screenLoading: true
});

const renderKeys = reactive({
  themes: 0,
  operators: 0,
  statuses: 0,
  themeList: 'themeList',
  operatorList: 'operatorList',
  statusesList: 'statusesList'
});

const timer = ref(null);

async function fetchData() {
  if (props.archive)
    setupArchive();

  dropDate();
  await _getAppeals();
  if (appeals.items.length > 0)
    flags.emptyOrgAppeals = false;

  flags.fromLoad = false;
  dropPagination();
  flags.screenLoading = false;
}

function setupArchive() {
  renderKeys.operatorList += 'A';
  renderKeys.statusesList += 'A';
  renderKeys.themeList += 'A';
}

async function _getAppeals() {
  flags.appealsLoading = true;
  let result;

  if (props.archive)
    result = await getArchiveAppeals(props.user.orgId, filterParams, searchParams);
  else
    result = await getAppeals(props.user.orgId, filterParams, searchParams);

  flags.error = result.error ?? false;
  if (result.error) {
    appeals.items = [];
  } else {
    appeals.items = result.appeals ?? [];
    totalAppealsCount.value = result.totalCount;
  }

  flags.appealsLoading = false;
}

function dropPagination() {
  updatePagination();
  searchParams.selectedPage = 1;
}

function updatePagination() {
  pagination.pMax = Math.ceil(totalAppealsCount.value / searchParams.pageSize);
  if (pagination.pMax <= 0) {
    pagination.pMax = 1;
    return;
  }

  pagination.pDelta = 3;
  if (pagination.pMax <= pagination.pDelta)
    pagination.pDelta = pagination.pMax;
}

function dateRangeCheck() {
  if (filterParams.selectedDateRange.endDate === '')
    filterParams.selectedDateRange.endDate = filter.maxDate.toLocaleDateString('en-CA');

  if (filterParams.selectedDateRange.startDate === '')
    filterParams.selectedDateRange.startDate = filter.minDate.toLocaleDateString('en-CA');
}

async function getAppealsWithPaginationChange() {
  searchParams.selectedPage = 1;
  await _getAppeals();
  updatePagination();
}

function selectPage(page) {
  if (page === searchParams.selectedPage)
    return;
  if (page >= 1 && page <= pagination.pMax) {
    searchParams.selectedPage = page;
    _getAppeals();
  }
}

async function openAppeal(appeal) {
  await router.push({ 
    name: 'Appeal', 
    params: {id: appeal.appealId, text: `Обращение ${appeal.num}` } 
  });
}

async function openAppealInNewTab(appeal) {
  let routeData = router.resolve({ 
    name: 'Appeal', 
    params: {id: appeal.appealId, text: `Обращение ${appeal.num}` } 
  });
  window.open(routeData.href, '_blank');
}

function sortDate() {
  sort('date');
}

function sortTheme() {
  sort('theme');
}

function sortStatus() {
  sort('status');
}

function sort(column) {
  if (searchParams.column == column) {
    searchParams.descending = !searchParams.descending;
  } else {
    searchParams.column = column;
    searchParams.descending = true;
  }
  getAppealsWithPaginationChange();
}

function updateAppealsWithTimer() {
  clearTimeout(timer.value);
  dateRangeCheck();

  timer.value = setTimeout(() => {
    getAppealsWithPaginationChange();
  }, 800);
}

function dropDate() {
  let maxDate = new Date();
  let numOfMonths = 12;

  if (props.archive)
    numOfMonths = 24;

  filter.maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() - numOfMonths);

  filter.minDate = maxDate;
  filterParams.selectedDateRange.endDate = filter.maxDate.toLocaleDateString('en-CA');
  filterParams.selectedDateRange.startDate = filter.minDate.toLocaleDateString('en-CA');
}

function filterCallback(filterOptions, filterObject) {
  Object.assign(filterParams, filterOptions);
  Object.assign(filter, filterObject);
  updateAppealsWithTimer();
} 

function search() {
  updateAppealsWithTimer();
}

function resize() {
  if (content.value) {
    flags.tableOverflownX = content.value.clientWidth < 720;
  }
}

const alertText = computed(() => {
  if (flags.error)
    return 'Произошла ошибка. Попробуйте позже';
  if (flags.fromLoad && appeals.items.length === 0) {
    return 'В данный момент у Вас нет обращений.';
  }

  return 'По Вашему запросу ничего не найдено';
});

const tableColumnFuns = computed(() => [null, sortDate, sortTheme, null, null, sortStatus, null]);

const appealsContentVisibility = computed(() => {
  return !(flags.screenLoading || flags.error || flags.emptyOrgAppeals)
});

function searchCallback(text) {
  searchParams.search = text;
  search();
}

const errorVisibile = computed(() => !flags.screenLoading && (flags.error || flags.emptyOrgAppeals));

onMounted(() => {
  resize();
  
  window.addEventListener('resize', resize);
  if (props.user)
    fetchData();
});

onUnmounted(() => {
  window.removeEventListener('resize', resize);
});
</script>

<template>
  <div 
    class="appeals-plate container-fluid d-flex flex-column h-100 w-100" 
    ref="content">
    <div v-if="errorVisibile" 
      class="appeals-plate__alert d-flex h-100 w-100 justify-content-center align-items-center">
      <img 
        class="appeals-plate__alert-icon" 
        src="@/assets/img/icons/icon-no-appeals.svg" 
        alt="no-appeals">
      <p class="appeals-plate__alert-text font-16 text-gray normal-font">
        {{ alertText }}
      </p>
    </div>
    <appeal-search v-if="appealsContentVisibility"
      :callback="searchCallback">
    </appeal-search>
    <div class="appeals-plate__content">
      <div v-if="flags.screenLoading"
        class="appeals-plate__loader d-flex justify-content-center 
        align-items-center h-100">
        <div 
          class="appeals-plate__spinner spinner-border" 
          role="status">
        </div>
      </div>
      <div v-else
        class="appeals-plate__appeals d-flex flex-row
        flex-nowrap alert-sign h-100 w-100">
        <!--Filter-->
        <appeal-filter
          :filter-types="['theme', 'operator', 'status']"
          :archive="archive"
          :callback="filterCallback"
          :org-id="user.orgId">
        </appeal-filter>
        <div v-if="appeals.items.length === 0 && !flags.appealsLoading"
          class="appeals-plate__appeals-alert d-flex flex-row h-100 w-75 
            justify-content-center align-items-center">
          <img 
            class="appeals-plate__alert-icon" 
            src="@/assets/img/icons/icon-no-appeals.svg" 
            alt="no-appeals">
          <p class="appeals-plate__alert-text font-16 text-gray normal-font m-0">
            {{ alertText }}
          </p>
        </div>
        <div v-else 
          id="table-pagination-layout" 
          class="appeals-plate__table d-flex flex-column flex-wrap w-75
          align-items-center no-horizontal-padding"
          :class="{'overflow-table': flags.tableOverflownX}">
          <!--Table-->
          <div v-if="flags.appealsLoading"
            class="row d-flex flex-row justify-content-center align-items-center h-100">
            <div 
              class="spinner-border" 
              role="status"/>
          </div>
          <project-table v-else
            class="appeals-plate__appeals-table d-flex flex-row w-100" 
            :items="appeals.items" 
            :item-click-exact="openAppeal" 
            :item-click-ctrl="openAppealInNewTab"
            :col-funs="tableColumnFuns" 
            :descending="searchParams.descending"
            :sort-by="searchParams.column" 
            :type="0"/>
          <!--Pagination-->
          <Pagination v-if="!flags.appealsLoading" 
            class="appeals-plate__pagination-content d-flex ms-auto" 
            id="pagination-layout"
            :min="pagination.pMin" 
            :max="pagination.pMax" 
            :key="renderKeys.pagination"
            :p-range="searchParams.pageSize" 
            :select-page="selectPage" 
            :selected-page="searchParams.selectedPage"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/css/pages/appeals-plate.scss';

.appeals-plate__appeals-alert {
  gap: 20px;
}
</style>