<script setup>
import CheckboxList from '@/components/layout/CheckboxList';
import { defineProps, defineExpose, reactive, ref } from 'vue';


const props = defineProps({
    filterName: String,
    filterOptions: Array,
    callback: Function,
    renderKey: Number,
    renderName: String,
    forceRerender: Function
});

const checkBoxes = reactive({
    items: []
});

const selectedCheckboxes = reactive({
    items: []
});

const expanded = ref(true);

function drop() {
    selectedCheckboxes.items = [];
    updateCheckboxes();
}

function changeSelected(selected) {
    if (selectedCheckboxes.items.includes(selected)) {
        selectedCheckboxes.items = selectedCheckboxes.items.filter(i => i !== selected);
    } else {
        selectedCheckboxes.items.push(selected);
    }

    updateCheckboxes();
}

function updateCheckboxes() {
    initCheckboxes();
    props.callback(selectedCheckboxes.items);
}

function initCheckboxes() {
    checkBoxes.items = getCheckboxConditions(
        props.filterOptions,
        selectedCheckboxes.items
    );
    props.forceRerender();
}

function getCheckboxConditions(initArray, selected) {
    if (initArray.length <= 0 )
        return [];
    
    let array = Array(initArray.length).fill(false);

    if (initArray[0].userId) {
        for (let i = 0; i < initArray.length; i++) {
            array[i] = selected.findIndex(item => item.userId === initArray[i].userId) != -1;
        }
    } else {
        for (let i = 0; i < initArray.length; i++) {
            array[i] = selected.findIndex(item => item.id === initArray[i].id) != -1;
        }
    }

    return array;
}

defineExpose({
    drop,
    initCheckboxes
});
</script>

<template>
    <div class="filter-item mb-2">
        <div class="filter-item__header d-flex flex-row align-items-center mb-3">
            <p class="filter-item__header-name w-auto text-black font-14 normal-font m-0 p-0">
                {{ filterName }}
            </p>
            <p 
                class="filter-item__drop w-auto text-gray font-10 underline-dashed mt-0 mb-0" 
                role="button" 
                @click="drop">
                Сбросить
            </p>
            <div class="col d-flex flex-row-reverse">
                <img 
                    class="icon-12 col-auto no-horizontal-padding" 
                    src="../../assets/img/icons/ic-dropdown-arrow.svg"
                    :alt="(expanded) ? 'Свернуть список' : 'Развернуть список'" 
                    data-bs-toggle="collapse" 
                    :data-bs-target="'#' + renderName"
                    @click="expanded = !expanded" 
                    role="button"
                    :class="{'rotate-180': !expanded}"/>
            </div>
        </div>
        <div 
            class="filter-item__colapse-list collapse show"
            :id="renderName">
            <checkbox-list 
                :key="renderKey"
                :list="filterOptions" 
                :on-click="changeSelected" 
                :checkbox-conditions="checkBoxes.items"/>
        </div>
    </div>
</template>

<style scoped>
.d-flex {
    column-gap: 14px;
}

.filter-item__colapse-list {
    padding: 0 !important;
    margin: 0 !important;
    max-height: none !important;
}
</style>