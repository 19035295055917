<script setup>
import { ref, defineProps } from 'vue';


const props = defineProps({
    callback: Function
});

const searchQuery = ref(null);

function search() {
    props.callback(searchQuery);
}
</script>

<template>
<div
    class="appeals-plate__search form-group d-flex flex-row align-items-center">
    <img 
        class="appeals-plate__search-icon fa fa-search form-control-icon user-select-none"
        src="@/assets/img/icons/icon-search.svg"
        alt="search" 
        draggable="false">
    <input 
        class="appeals-plate__search-input form-control no-border" 
        type="text" 
        placeholder="Поиск по № обращения, теме, первому комментарию, адресу и лицевому счету" 
        v-model="searchQuery" 
        @input="search"> 
</div>
</template>

<style scoped lang="scss">
@import '@/assets/css/colors.scss';


.form-group .form-control {
  padding-left: 52px;
  background: $secondary-color;
  height: 56px;
  border-radius: 4;
}

.form-group .form-control-icon {
  position: absolute;
  z-index: 2;
  display: block;
  width: 24px;
  height: 24px;
  margin: 16px;
  padding: 0;
}
</style>