<script setup>
import { reactive, onMounted, defineProps } from 'vue';


const props = defineProps({
  list: Array,
  onClick: Function,
  checkboxConditions: Array
});

const checkbox = reactive({
  items: []
});

const getName = (item) => {
  if (item.text)
    return item.text;
    
  if (item?.fio)
    return item?.fio;

  return item.name;
};

onMounted(() =>
    checkbox.items = props.checkboxConditions
);
</script>

<template>
  <ul class="checkbox-list mb-0 ps-0">
    <li class="checkbox-list__checkbox list-without-style w-auto
      d-flex flex-row justify-content-start align-items-center mb-2"
      v-for="(n, i) in props.list.length" 
      role="button"
      @click="props.onClick(props.list[i])" 
      :key="i">
      <input 
        class="checkbox-list__form-check form-check-input checkbox" 
        type="checkbox"
        role="button"
        v-model="checkbox.items[i]"/>
      <p class="checkbox-list__name text-black font-12 normal-font 
        my-0 ms-3">
        {{ getName(props.list[i]) }}
      </p>
    </li>
  </ul> 
</template>

<style scoped>
.checkbox-list__checkbox {
  padding: 0 !important;
}
</style>