<script setup>
import { onMounted, reactive, defineProps, computed } from 'vue';


const props = defineProps({
  selectedPage: Number,
  min: Number,
  max: Number,
  pRange: Number,
  selectPage: Function
});

const params = reactive({ 
  selectedPage: 1,
  pageDelta: 3
});

function isArrowInactive(change) {
  return (change < 0) 
    ? params.selectedPage + change <= 0 
    : params.selectedPage + change > props.max;
}

function nextPage() {
  let newPage = params.selectedPage + 1;
  if (newPage <= props.max)
    props.selectPage(newPage);
}

function prevPage() {
  let newPage = params.selectedPage - 1;
  if (newPage > 0)
    props.selectPage(newPage);
}

const isLeftArrowInactive = computed(() => params.selectedPage === 1);
const isRightArrowInactive = computed(() => params.selectedPage === props.max);
const pStart = computed(() => params.selectedPage > 1 ? params.selectedPage - 1 : 1);
const pEnd = computed(() => params.selectedPage < props.max ? params.selectedPage + 1 : props.max);

const isMinSelected = computed(() => params.selectedPage === props.min);
const isMaxSelected = computed(() => props.max > 5 && params.selectedPage === props.max);
const isMinVisible = computed(() => params.selectedPage > 1);
const isMaxVisible = computed(() => params.selectedPage < props.max);

const isMinDotsVisible = computed(() => params.selectedPage - 2 > 1);
const isMaxDotsVisible = computed(() => params.selectedPage + 2 < props.max);
const isStartVisible = computed(() => params.selectedPage - 1 > 1);
const isEndVisible = computed(() => params.selectedPage + 1 < props.max);

onMounted(() => {
  params.selectedPage = props.selectedPage;
  params.pageDelta = props.pRange;

  if (props.max <= 0)
    return;
  
  params.pageDelta = (props.max <= params.pageDelta) ? props.max : 3;
});
</script>

<template>
  <ul class="pagination no-margin-vertical user-select-none normal-font font-14">
    <li 
      class="pageination__page-item"
      @click="selectPage(1)">
      <a 
        class="paginatation__page-link no-border"
        :class="{ 'text-gray': isLeftArrowInactive, 'text-black': !isLeftArrowInactive }"
        href="#">
        &lt;&lt;
      </a>
    </li>
    <li 
      class="pageination__page-item"
      @click="prevPage()">
      <a 
        class="paginatation__page-link no-border"
        href="#"
        :class="{ 'text-gray': isArrowInactive(-1), 'text-black': !isArrowInactive(-1) }">
        &lt;
      </a>
    </li>
    <li 
      class="pageination__page-item"
      @click="selectPage(1)"
      :class="{ 'collapse': !isMinVisible && !isMinSelected }">
      <a 
        class="paginatation__page-link no-border"
        :class="{ 'text-blue': isMinSelected, 'text-black': !isMinSelected }"
        href="#">
        1
      </a>
    </li>
    <li 
      class="pageination__page-item"
      :class="{ 'collapse': !isMinDotsVisible }" 
      aria-disabled="true" >
      <a 
        class="paginatation__page-link text-black no-border"
        href="#">
        ...
      </a>
    </li>
    <li 
      class="pageination__page-item"
      :class="{ 'collapse': !isStartVisible }" 
      @click="selectPage(pStart)">
      <a 
        class="paginatation__page-link text-black no-border"
        href="#">
        {{ pStart }}
      </a>
    </li>
    <li 
      class="pageination__page-item"
      :class="{ 'collapse': isMinSelected || isMaxSelected }">
      <a 
        class="paginatation__page-link text-blue no-border"
        href="#">
        {{ selectedPage }}
      </a>
    </li>
    <li 
      class="pageination__page-item"
      :class="{ 'collapse': !isEndVisible }" 
      @click="selectPage(pEnd)">
      <a 
        class="paginatation__page-link text-black no-border"
        href="#">
        {{ pEnd }}
      </a>
    </li>
    <li 
      class="pageination__page-item"
      :class="{ 'collapse': !isMaxDotsVisible }" 
      aria-disabled="true">
      <a 
        class="paginatation__page-link text-black no-border"
        href="#">
        ...
      </a>
    </li>
    <li 
      class="pageination__page-item"
      @click="selectPage(max)"
      :class="{ 'collapse': !isMaxVisible && !isMaxSelected }">
      <a 
        class="paginatation__page-link no-border"
        :class="{ 'text-blue': isMaxSelected, 'text-black': !isMaxSelected }"
        href="#">
        {{ max }}
      </a>
    </li>
    <li 
      class="pageination__page-item"
      @click="nextPage()">
      <a 
        class="paginatation__page-link no-border"
        href="#" 
        :class="{ 'text-gray': isArrowInactive(1), 'text-black': !isArrowInactive(1) }">
        &gt;
      </a>
    </li>
    <li 
      class="pageination__page-item"
      @click="selectPage(max)">
      <a 
        class="paginatation__page-link no-border"
        :class="{ 'text-gray': isRightArrowInactive, 'text-black': !isRightArrowInactive }"
        href="#">
        &gt;&gt;
      </a>
    </li>
  </ul>
</template>

<style scoped>

.paginatation__page-link {
  padding: 6px;
  text-decoration: none;
}
</style>