<script setup>
import ProjectTableHeader from "@/components/ProjectTableHeader";
import iAppealColumns from "@/assets/appeal-columns.json";
import { ref, onMounted, defineProps } from 'vue';


const props = defineProps({
  items: Array,
  itemClickExact: Function,
  itemClickCtrl: Function,
  colFuns: Array,
  descending: Boolean,
  sortBy: String,
  type: Number
});

const numOfCol = ref(0);
const tableTypes = ['appeal', 'archive', 'event'];
const tableRows = ref(null);
const columns = ref(null);
const renderKeyHeader = ref(0);
const appealRowComments = [2,3,4];

function mapAppealToRow(appeal) {
  let item = Object.assign({}, appeal);
  let row = {};

  row.appealId = appeal.appealId;
  row.messages = item.messages;
  numOfCol.value = 7;
  row.items = [];
  for (let i = 0; i < columns.value.length; i++)
    row.items.push( {text: "", width: ""});

  row.items[0].text = item.number;
  row.items[1].text = item.createDate;
  row.items[2].text = item.themes.themes[0]?.name ?? '-';
  row.items[3].text = item.messages[0]?.comment ?? '-';
  row.items[4].text = item.address ?? '-';
  row.items[5] = item.status;
  row.items[6].text = item.messages.filter(m => m.operatorRead === false).length;

  for (let i = 0; i < columns.value.length; i++) {
    row.items[i].width = columns.value[i].width;
  }
  return row;
}

const appealColumns = () => {
  let cols = [...iAppealColumns];
  for (let i = 0; i < cols.length; i++) {
    cols[i].fun = props.colFuns[i];
    cols[i].sortFlag = props.sortBy === cols[i].colNm;
  }
  cols[0].width = 'mid';
  cols[1].width = 'mid';
  cols[2].width = 'small';
  cols[3].width = 'big';
  cols[4].width = 'mid';
  cols[5].width = 'small';
  cols[6].width = 'small';

  return cols;
};

const countColorRed = (item, i) => {
  return columnBubble(i) && item.messages.filter(m => m.operatorRead === false).length > 0
};

const countColorGray = (item, i) => {
  return columnBubble(i) && item.messages.filter(m => m.operatorRead === false).length === 0
};

const appealNormalFont = (item) => {
  return item.items[5].id === 0;
};

const highlightBlue = (item, j) => {
  return appealHighlightBlue(item, j);
};

const highlightRed = (item, j) => {
  return appealHighlightRed(item, j);
};

const highlightGray = (item, j) => {
  return appealHighlightGray(item, j);
};

const appealHighlightBlue = (item, j) => {
  return tableTypes[props.type] === 'appeal'
      && j === 5 && item.highlight === 'blue'
};

const appealHighlightRed = (item, j) => {
  return tableTypes[props.type] === 'appeal'
      && j === 5 && item.highlight === 'red'
};

const appealHighlightGray = (item, j) => {
  return tableTypes[props.type] === 'appeal'
      && j === 5 && item.highlight === 'gray'
};

const midWidth = (innerItem) => {
  return innerItem.width === 'mid';
};

const smallWidth = (innerItem) => {
  return innerItem.width === 'small';
};

const columnBubble = (id) => {
  return tableTypes[props.type] === 'appeal' && id === 6;
};

const isRowComment = (j) => {
  return isRowCommentAppeal(j);
};
const isRowCommentAppeal = (j) => {
  return appealRowComments.includes(j);
};

const isTableComment = (j) => {
  return isTableCommentAppeal(j);
};

const isTableCommentAppeal = (j) => {
  return j === 3;
};

onMounted(() => {
  let dItems = [...props.items];

  if (tableTypes[props.type] === 'appeal') {
    columns.value = appealColumns();
    dItems = dItems.map(mapAppealToRow);
    tableRows.value = [...dItems];
    renderKeyHeader.value += 1;
  }
});
</script>

<template>
  <table 
    id="table"
    class="table-template d-flex flex-column flex-wrap table table-striped 
    table-hover table-responsive user-select-none">
    <thead class="table-template__head">
      <tr 
        class="table-template__column font-12 col-text-alignment" 
        :key="renderKeyHeader">
        <project-table-header 
          v-for="header in columns" 
          v-bind:key="header"
          :sort="header.sort" 
          :sort-flag="header.sortFlag"
          :name="header.name" 
          :click="header.fun"
          :descending="props.descending" 
          :width="header.width"/>
      </tr>
    </thead>
    <tbody class="table-template__content d-lg-table">
      <tr 
        class="table-template__row row-fixed-height align-middle" 
        role="button" 
        v-for="row in tableRows"
        :class="{'normal-font': appealNormalFont(row), 
          'mid-font': !appealNormalFont(row)}"
        :key="row" 
        @click.exact="itemClickExact(row)" 
        @click.ctrl="itemClickCtrl(row)" 
        @click.meta="itemClickCtrl(row)">
        <td 
          class="table-template__column font-12 text-black" 
          v-for="(rowItem, rowItemKey) in row.items" 
          :key="rowItemKey"
          :class="{'w-10': smallWidth(rowItem), 'w-15': midWidth(rowItem),
            'w-25': !smallWidth(rowItem) && !midWidth(rowItem),
            'highlight-blue': highlightBlue(rowItem, rowItemKey), 
            'highlight-red': highlightRed(rowItem, rowItemKey),
            'highlight-gray': highlightGray(rowItem, rowItemKey)}">
          <p 
            class="table-template__last-column no-margin-vertical"
            :class="{'bubble': columnBubble(rowItemKey), 
              'highlight-background-red': countColorRed(row, rowItemKey), 
              'highlight-background-gray': countColorGray(row, rowItemKey),
              'row-comment': isRowComment(rowItemKey), 
              'table-comment': isTableComment(rowItemKey)}">
            {{ rowItem.text }}
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';


table {
  --bs-table-striped-bg: #F3F7F8 !important;
  border-color: #F3F7F8 !important;
  table-layout: fixed;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.table>:not(:first-child) {
  border-top: none;
}

tr {
  line-height: 25px !important;
  min-height: 25px;
  height: 25px !important;
}

thead {
  border-bottom: white;
  height: 41px !important;
}

.bubble {
  height: 32px;
  width: 32px;
  line-height: 14px;
  display: table-cell;
  border-radius: 50%;
  background-color: $light-gray;
  vertical-align: middle;
  text-align: center;
  color: white;
}

.highlight-background-red {
  background-color: $color-red;
}

.highlight-background-gray {
  background-color: $light-gray;
}

.highlight-blue {
  color: $primary-color !important;
}

.highlight-red {
  color: $color-red !important;
}

.highlight-gray {
  color: $light-gray !important;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: $light-primary !important;
}

.row-comment {
  width: 100%;
  height: 100%;
  padding: 0;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
}

.table-comment {
  width: 200px !important;
}

.row-fixed-height {
  height: 56px !important;
  max-height: 56px !important;
}

.col-text-alignment {
  vertical-align: top;
}
</style>