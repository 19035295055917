<script setup>
import AppealsPlate from '@/views/appeals/AppealsPlate';
import storedUser from '@/utils/storedUser';
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { getThemes, getOperators } from '@/api/appeals';

const router = useRouter();
const route = useRoute();

const appealsRenderKey = ref(0);
const archiveRenderKey = ref(1);

const { userError, user } = storedUser();

const themes = ref(null);
const operators = ref(null);
const screenLoading = ref(true);

// const forceRerender = () => {
//   appealsRenderKey.value += 1;
//   archiveRenderKey.value += 1;
// };

const openTab = (tabName) => {
  router.replace({ path: route.path, query: { tab: tabName } })
};

const fetchData = async () => {
  await getThemes(user.orgId).then((res) =>
    themes.value = res
  );
    
  await getOperators(user.orgId).then((res) =>
    operators.value = res.data
  );

  if (operators.value && themes.value) {
    // forceRerender();
    activateTab();
  } 

  screenLoading.value = false;
}

const activateTab = () => {
  if (route.query.tab){
    const tabName = route.query.tab;
    document.querySelector('.nav-item .active').classList.remove('active');
    document.querySelector('#'+tabName+'-tab').classList.add('active');
    document.querySelector('.tab-content .tab-pane').classList.remove('active');
    document.querySelector('#'+tabName).classList.add('active');
  }
};

onMounted(() => {
  if (userError) {
    router.push('login');
  } else {
    fetchData();
  }
});
</script>

<template>
  <div class="appeals container content d-flex">
    <div class="appeals__content flex-fill h-100 w-100">
      <ul 
        class="appeals__appeals-list nav nav-tabs no-border" 
        id="appealsTabs" 
        role="tablist">
        <li 
          class="appeals__appeal-tabs nav-item" 
          role="presentation">
          <button 
            class="appeals__tab-button nav-link normal-font font-16 no-border active" 
            aria-controls="appeals" 
            aria-selected="true" 
            id="appeals-tab" 
            type="button" 
            role="tab" 
            data-bs-target="#appeals" 
            data-bs-toggle="tab" 
            @click="openTab('appeals')">
            Обращения
          </button>
        </li>
        <li 
          class="appeals__appeal-tabs nav-item" 
          role="presentation">
          <button 
            class="appeals__tab-button nav-link normal-font font-16 no-border" 
            aria-controls="archive"
            aria-selected="false" 
            id="archive-tab" 
            type="button" 
            role="tab"
            data-bs-target="#archive" 
            data-bs-toggle="tab" 
            @click="openTab('archive')">
            Архивные обращения
          </button>
        </li>
      </ul>
      <!--Tab panes-->
      <div class="appeals__content-pane tab-content d-block tabs padding-16 bg-white border-radius-4">
        <div v-if="screenLoading" 
          class="appeals__loader d-flex justify-content-center align-items-center h-100">
          <div 
            class="appeals__spinner spinner-border" 
            role="status"/>
        </div>
        <div 
          class="appeals__active-appeals tab-pane active" 
          role="tabpanel" 
          id="appeals" 
          aria-labelledby="appeals-tab">
          <AppealsPlate 
            v-if="!screenLoading" 
            :user="user" 
            :archive="false"
            :init-themes="themes" 
            :init-operators="operators" 
            :key="appealsRenderKey"/>
        </div>
        <div 
          class="appeals__archive-appeals tab-pane" 
          role="tabpanel" 
          id="archive" 
          aria-labelledby="archive-tab">
          <AppealsPlate 
            v-if="!screenLoading" 
            :user="user" 
            :archive="true"
            :init-themes="themes" 
            :init-operators="operators" 
            :key="archiveRenderKey"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/css/colors.scss';
.nav-tabs .active{
  background: white !important;
  color: $primary-color;
  border-radius: 4px 4px 0 0;
}
.no-border {
  border: none;
}
.tab-content {
  padding: 30px 24px 24px 24px;
}
.tabs {
  max-height: calc(100% - 40px) !important;
}
</style>