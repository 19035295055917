<script setup>
import FilterItem from './FilterItem.vue';
import { defineProps, reactive, onMounted, ref } from 'vue';
import { getThemes, getOperators } from '@/api/appeals';
import { getClosedStatuses, getOpenStatuses } from '@/utils/appeal-utils';


const props = defineProps({
    filterTypes: Array,
    callback: Function,
    archive: Boolean,
    orgId: Number
});

const filter = reactive({
    themes: [],
    themesCollapsed: false,
    operators: [],
    operatorsCollapsed: false,
    statuses: [],
    statusesCollapsed: false,
    minDate: new Date(),
    maxDate: new Date(),
    checkboxSelect: {
        status: [],
        operator: [],
        theme: []
    }
});

const filterParams = reactive({
    selectedOperators: [],
    selectedThemes: [],
    selectedStatuses: [],
    selectedDateRange: {
        startDate: '',
        endDate: ''
    }
});

const renderKeys = reactive({
    themes: 0,
    operators: 0,
    statuses: 0,
    themeList: 'themeList',
    operatorList: 'operatorList',
    statusList: 'statusesList'
});

function setupArchive() {
    renderKeys.operatorList += 'A';
    renderKeys.statusList += 'A';
    renderKeys.themeList += 'A';
}

async function initFilter() {
    dropDate();

    filter.statuses = getStatuses();

    await _getThemes();
    await _getOperators();

    initFilterCheckboxes();
}

const themeFilter = ref(null);
const operatorFilter = ref(null);
const statusFilter = ref(null);

function initFilterCheckboxes() {
    themeFilter.value?.initCheckboxes();
    operatorFilter.value?.initCheckboxes();
    statusFilter.value?.initCheckboxes();
}

function dropDate() {
    let maxDate = new Date();
    let numOfMonths = 12;

    if (props.archive)
        numOfMonths = 24;

    filter.maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() - numOfMonths);

    filter.minDate = maxDate;
    filterParams.selectedDateRange.endDate = filter.maxDate.toLocaleDateString('en-CA');
    filterParams.selectedDateRange.startDate = filter.minDate.toLocaleDateString('en-CA');
}

function getStatuses() {
    if (props.archive)
        return getClosedStatuses();

    return getOpenStatuses();
}

async function _getThemes() {
    let result = await getThemes(props.orgId);
    if (!result.error && result.themes.length > 0)
        formThemes(result.themes);
}

function formThemes(themeList) {
    if (!themeList)
        return;

    let themes = [];
    for (const theme of themeList) {
        if (theme.name) {
        let subThemes = theme.themes.slice();
        themes = themes.concat(subThemes);
        }
    }
    filter.themes = themes;
}

async function _getOperators() {
    let result = await getOperators(props.orgId);
    if (!result.error) {
        filter.operators = result.data;
    }
}

function rerenderThemes() {
    renderKeys.themes += 1;
}

function rerenderOperators() {
    renderKeys.operators += 1;
}

function rerenderStatuses() {
    renderKeys.statuses += 1;
}

function itemCallback() {
    props.callback(filterParams, filter);
}

function themeCallback(selected) {
    filterParams.selectedThemes = selected;
    itemCallback();
}

function operatorCallback(selected) {
    filterParams.selectedOperators = selected;
    itemCallback();
}

function statusCallback(selected) {
    filterParams.selectedStatuses = selected;
    itemCallback();
}

function dropAllFilters() {
    filterParams.selectedThemes = [];
    filterParams.selectedStatuses = [];
    filterParams.selectedOperators = [];

    themeFilter.value.drop();
    operatorFilter.value.drop();
    statusFilter.value.drop();
    dropDate();
    itemCallback();
}

onMounted(() => {
    if (props.archive)
        setupArchive();

    initFilter();
});
</script>

<template>
    <section class="filter d-flex flex-column px-0 h-100 w-25">
        <div class="filter__header d-flex flex-row padding-8 justify-content-between">
            <div class="filter__header-info d-flex flex-row align-items-center">
                <img 
                    class="filter__header-icon icon-24" 
                    src="../../assets/img/icons/icon-filter.svg" 
                    alt="filter-icon"/>
                <p class="filter__header-text text-black font-14 normal-font my-0">
                    Фильтровать
                </p>
            </div>
            <div class="filter__header-drop d-flex align-items-center">
                <p 
                    class="filter__drop-all text-gray font-12 underline-dashed my-0"
                    role="button"
                    @click="dropAllFilters()">
                    Сбросить всё
                </p>
            </div>
        </div>
        <div class="filter__content d-flex flex-column border-radius-4 bg-gray p-3">
            <div class="filter__by-date d-flex flex-column w-100 mb-3">
                <div class="filter__date-filter-title d-flex flex-row 
                    justify-content-start w-auto align-items-center">
                    <p class="filter__date-title text-black font-14 normal-font mb-0">
                        По дате создания
                    </p>
                    <p 
                        class="filter__date-drop text-gray font-10 underline-dashed w-auto mb-0" 
                        role="button"
                        @click="dropDateClick()">
                        Сбросить
                    </p>
                </div>
                <div class="filter__date-input d-flex flex-row">
                    <div class="filter__date-from w-100">
                        <label 
                            class="filter__date-from-label date-label position-absolute font-12 text-gray" 
                            for="start">
                            от
                        </label>
                        <input 
                            class="filter__date-from-input date-range-input font-14 w-100" 
                            type="date" 
                            @change="itemCallback()"
                            id="start" 
                            v-model="filterParams.selectedDateRange.startDate"
                            :min="filter.minDate.toLocaleDateString('en-CA')" 
                            :max="filterParams.selectedDateRange.endDate"/>
                    </div>
                    <div class="filter__date-to w-100">
                        <label 
                            class="filter__date-to-label date-label position-absolute font-12 text-gray" 
                            for="end">
                            до
                        </label>
                        <input 
                            class="filter__date-to-input date-range-input font-14 w-100" 
                            type="date" 
                            @change="itemCallback()"
                            id="end" 
                            v-model="filterParams.selectedDateRange.endDate"
                            :min="filterParams.selectedDateRange.startDate" 
                            :max="filter.maxDate.toLocaleDateString('en-CA')"/>
                    </div>
                </div>
            </div>
            <filter-item v-if="filterTypes.includes('theme') && filter.themes.length > 0" 
                ref="themeFilter"
                filter-name="По теме"
                :filter-options="filter.themes"
                :callback="themeCallback"
                :render-key="renderKeys.themes"
                :render-name="renderKeys.themeList"
                :force-rerender="rerenderThemes"/>
            <filter-item v-if="filterTypes.includes('operator') && filter.operators.length > 0"
                ref="operatorFilter" 
                filter-name="По оператору"
                :filter-options="filter.operators"
                :callback="operatorCallback"
                :render-key="renderKeys.operators"
                :render-name="renderKeys.operatorList"
                :force-rerender="rerenderOperators"/>
            <filter-item v-if="filterTypes.includes('status') && filter.statuses.length > 0" 
                ref="statusFilter"
                filter-name="По статусу"
                :filter-options="filter.statuses"
                :callback="statusCallback"
                :render-key="renderKeys.statuses"
                :render-name="renderKeys.statusList"
                :force-rerender="rerenderStatuses"/>
        </div>
    </section>
</template>

<style lang="scss" scoped>
@import '@/assets/css/colors.scss';


.d-flex {
    column-gap: 14px;
}

.date-range-input {
    border-radius: 4px;
    border: none !important;
    margin-top: 8px;
    padding: 8px;
    text-align: right;
    outline-color: $primary-color;
}

::-webkit-calendar-picker-indicator {
    filter: invert(39%) sepia(97%) saturate(1688%) hue-rotate(180deg) brightness(91%) contrast(105%);
    cursor: pointer;
}

.date-label {
    margin-top: 19px;
    margin-left: 8px;
}
</style>