<script setup>
import { computed, defineProps } from 'vue';


const props = defineProps({
  sort: Boolean,
  sortFlag: Boolean,
  name: String,
  click: Function,
  descending: Boolean,
  width: String
});

function clickHeader () {
  if (props.click)
    props.click();
}

const midWidth = computed(() => props.width === 'mid');

const bigWidth = computed(() => props.width === 'big');
</script>

<template>
  <th 
    class="template-table-header text-gray font-12 thin-font col-text-alignment" 
    scope="col"
    role="button" 
    @click="clickHeader()"
    :class="{'w-10': !midWidth && !bigWidth,
      'w-15': midWidth,
      'w-25': bigWidth}">
    <img v-if="sort"
      class="template-table-header__image"
      :class="{'highlight-icon-blue': props.sortFlag, 
        'highlight-icon-gray': !props.sortFlag, 
        'rotate-180': !props.descending && props.sortFlag}" 
      draggable="false"
      src="@/assets/img/icons/icon-table-sort.svg" 
      alt="Sorting icon">
    {{ name }}
  </th>
</template>

<style scoped>
.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.col-text-alignment {
  vertical-align: top;
}
</style>